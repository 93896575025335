import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { OrderStatus, ProductionSpeed, ProductOptions, YesNo } from '../services/order/types';
import { IMailMergeField } from '../features/Editor/utils/mailMergeOptions';
import { IArtifact } from '../features/Editor/services/artifact/types';
import Stepper from '../components/Stepper';

interface ITemplateWorkflow {
  callTrackingSide?: 'Front' | 'Rear';
}

export interface ITemplateOrderIntersection extends ITemplateWorkflow {
  address1: string;
  address2: string;
  city: string;
  couponListId?: number | null;
  customerEmail: string;
  customerId: string;
  description: string;
  doubleSided: YesNo;
  firstName: string;
  font: string;
  fontSize: number;
  fontSizeReturnAddress: number;
  hasDiscount: boolean;
  id: number;
  isIntegrationOrderTemplate: boolean;
  isNew: boolean;
  isTemplate: boolean;
  lastName: string;
  multiUseCouponId?: number | null;
  name: string;
  noFrontLogo: string;
  orderArtifacts: IArtifact[];
  orderParameters: IMailMergeField[];
  orderStatus: OrderStatus;
  orderVolume: number;
  postageType: string;
  product: ProductOptions;
  productionSpeed: ProductionSpeed;
  profileId: number;
  qrAngle?: number | null;
  qrCodeHeight?: number | null;
  qrCodeSide?: 'Rear' | 'Front' | null;
  qrCodeWidth?: number | null;
  qrCodeX?: number | null;
  qrCodeY?: number | null;
  qrUrl?: string | null;
  recipientsCsvBoxUrl: string;
  recipientsUploaded: boolean;
  returnAddress1: string;
  returnAddress2: string;
  returnCity: string;
  returnFirstName: string;
  returnLastName: string;
  returnOrganization: string;
  returnState: string;
  returnTitle: string;
  returnZip: string;
  sceneUrl: string;
  state: string;
  templateId: string;
  text: string;
  text2: string;
  trackingPhoneNumber: string;
  useQr: boolean;
  zip: string;
}

interface WorkflowContextProps<T extends ITemplateOrderIntersection> {
  step?: number;
  lastStep?: number;
  nextStep?: number;
  template: T;
  setTemplate: React.Dispatch<React.SetStateAction<T>>;
  maxStepAllowed: number;
  setMaxStepAllowed: React.Dispatch<React.SetStateAction<number>>;
  changeStep: (step: number, template?: T) => void;
  save: (template: T, callback?: () => void) => void;
  saveSteps: { [key: number]: Function };
  setSaveSteps: React.Dispatch<React.SetStateAction<{ [key: number]: Function }>>;
  isSaving: boolean;
}

const WorkflowContext = createContext<WorkflowContextProps<any> | undefined>(undefined);
WorkflowContext.displayName = 'WorkflowContext';

interface WorkflowContextProviderProps<T extends ITemplateOrderIntersection> {
  children: ReactNode;
  template: T;
  setTemplate: React.Dispatch<React.SetStateAction<T>>;
  steps: string[];
  save: (template: T, callback?: () => void) => void;
  isSaving: boolean;
}

export function WorkflowContextProvider<T extends ITemplateOrderIntersection>({
  children,
  template,
  setTemplate,
  steps,
  save,
  isSaving,
}: WorkflowContextProviderProps<T>) {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const step = queryParams.get('step');
  const stepIndex = typeof step === 'string' ? parseInt(step) : 1;
  const [saveSteps, setSaveSteps] = useState<{ [key: number]: Function }>({});
  const [maxStepAllowed, setMaxStepAllowed] = useState<number>(2);
  const [nextStep, setNextStep] = useState<number>();

  useEffect(() => {
    setNextStep(undefined);
  }, [stepIndex]);

  const changeStep = (newStep: number, updatedTemplate?: T) => {
    const callback = () => navigate(`${pathname}?step=${newStep}`, { replace: true });
    if (updatedTemplate) save(updatedTemplate, callback);
    else callback();
  };

  const handleStepperClick = (selectedStep: number) => {
    if (!saveSteps[stepIndex] || selectedStep < stepIndex) return changeStep(selectedStep);
    setNextStep(selectedStep);
    saveSteps[stepIndex]();
  };

  return (
    <WorkflowContext.Provider
      value={{
        step: stepIndex,
        lastStep: steps.length,
        nextStep,
        template,
        setTemplate,
        maxStepAllowed,
        setMaxStepAllowed,
        changeStep,
        save,
        saveSteps,
        setSaveSteps,
        isSaving,
      }}
    >
      <Stepper steps={steps} maxStepAllowed={maxStepAllowed} changeStep={handleStepperClick}>
        {children}
      </Stepper>
    </WorkflowContext.Provider>
  );
}

export function useWorkflowContext<T extends ITemplateOrderIntersection>() {
  const context = useContext(WorkflowContext);
  if (context) return context as WorkflowContextProps<T>;
  throw new Error('useWorkflowContext must be used within a WorkflowContextProvider');
}
