import { debounce } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { removeGlyphs } from 'helpers/glyphHelper';
import useFontService from 'newStandard/src/services/font';
import { getMaxCharacterCountForProduct } from 'helpers/OrderHelper';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { useEditorContext } from '../contexts/useEditorContext';
import { BlockNames } from '../utils/sceneEnums';

export default function useTemplateTextUpdate() {
  const { getFontGlyphs } = useFontService();
  const { template, setTemplate } = useWorkflowContext();
  const { engine, selectedFont } = useEditorContext();

  const { data: fontGlyphs } = useQuery({
    queryKey: ['font-glyphs', selectedFont?.id],
    queryFn: () => getFontGlyphs(selectedFont?.id),
  });

  const debouncedTemplateUpdate = debounce((text: 'text' | 'text2', auxText: string) => {
    setTemplate((prev) => {
      if (prev[text] === auxText) return prev;
      let maxChars = getMaxCharacterCountForProduct(template.product);
      if (template.product === ProductOptions.HandwrittenBiFoldCard) maxChars = maxChars / 2;
      if (auxText.length > maxChars) {
        auxText = auxText.substring(0, prev[text].length === maxChars ? maxChars - 1 : maxChars);
      }
      return { ...prev, [text]: auxText };
    });
  }, 300);

  const updateTemplatebyTextName = (text: 'text' | 'text2') => {
    const blockName = text === 'text' ? BlockNames.HandwrittenText : BlockNames.HandwrittenText2;
    const textBlockId = engine.block.findByName(blockName)[0];
    const auxText = removeGlyphs(engine.block.getString(textBlockId, 'text/text'), fontGlyphs?.value);
    debouncedTemplateUpdate(text, auxText);
  };

  const updateTemplatebyBlock = (block: number) => {
    const templateTextName = engine.block.getName(block) === BlockNames.HandwrittenText ? 'text' : 'text2';
    const auxText = removeGlyphs(engine.block.getString(block, 'text/text'), fontGlyphs?.value);
    debouncedTemplateUpdate(templateTextName, auxText);
  };

  const updateTemplateText = (block?: number) => {
    if (block) return updateTemplatebyBlock(block);
    updateTemplatebyTextName('text');
    if (template.product === ProductOptions.HandwrittenBiFoldCard) updateTemplatebyTextName('text2');
  };

  return { updateTemplateText };
}
