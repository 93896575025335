import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import CreativeEditorSDK, { ConfigTypes } from '@cesdk/cesdk-js';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import printedFonts from 'newStandard/src/assets/fonts/printedFonts.json';
import ProfileArtifactService from 'services/profile-artifact';
import { stretchImageToFullBleed } from 'helpers/ImgLyHelper';
import PremadeDesignService from 'services/premade-design';

import {
  advancedDockOrder,
  advancedInspectorBar,
  basicInspectorBar,
  basicOrderConfig,
  cardConfig,
  textModeCanvasMenuOrder,
} from '../utils/defaultConfig';
import { useEditorContext } from '../contexts/useEditorContext';
import { checkHasEnvelope } from '../utils/templateHelper';
import { ArtifactType } from '../services/artifact/types';
import useArtifactService from '../services/artifact';
import useFontService from '../../../services/font';
import { BlockNames } from '../utils/sceneEnums';

export default function useCreateCardEditor(cesdkRef: React.MutableRefObject<HTMLDivElement | null>) {
  const { template } = useWorkflowContext();
  const { engine, setEngine, setLettrLabsPanel, setReturnAddressPanel, setShowCardRear } = useEditorContext();

  const { getFonts } = useFontService();
  const { data: fonts } = useQuery({ queryKey: ['getFonts'], queryFn: getFonts });

  const { getPastOrderArtifacts, uploadOrderArtifact } = useArtifactService();
  const { getProfileArtifacts } = ProfileArtifactService();
  const { getPremadeDesigns } = PremadeDesignService();

  const { data: pastImages, isFetched: isPastImagesFetched } = useQuery({
    queryKey: ['getPastOrderArtifacts'],
    queryFn: getPastOrderArtifacts,
  });

  const { data: { payload: brandImages } = {}, isFetched: isBrandImagesFetched } = useQuery({
    queryKey: ['profileArtifacts'],
    queryFn: getProfileArtifacts,
  });

  const { data: premadeDesigns, isFetched: isPremadeDesignsFetched } = useQuery({
    queryKey: ['getPremadeDesigns'],
    queryFn: getPremadeDesigns,
  });

  useEffect(() => {
    if (
      !cesdkRef?.current ||
      !fonts?.length ||
      !isPastImagesFetched ||
      !isBrandImagesFetched ||
      !isPremadeDesignsFetched
    ) {
      return;
    }

    const uploadImageCallback: ConfigTypes.OnUploadCallback = async (file: File) => {
      const maxSizeBytes = 25 * 1024 * 1024; // 25 MB in bytes
      if (file.size >= maxSizeBytes) return Promise.reject(new Error('File size exceeds the limit of 25 MB'));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('artifactType', ArtifactType.Image);
      const image = await uploadOrderArtifact(template.id, formData);
      return Promise.resolve({ id: image.id.toString(), meta: { uri: image.blobUri, thumbUri: image.blobUri } });
    };

    let cleanedUp = false;
    let instance: CreativeEditorSDK;
    CreativeEditorSDK.create(cesdkRef.current, cardConfig(uploadImageCallback)).then(async (cesdk) => {
      instance = cesdk;
      if (cleanedUp) return instance.dispose();

      await instance.addDefaultAssetSources();
      await instance.addDemoAssetSources({ sceneMode: 'Design' });
      instance.disableNoSceneWarning();

      instance.engine.editor.setSettingBool('page/title/show', false);
      instance.ui.updateAssetLibraryEntry('ly.img.image', {
        sourceIds: ['ly.img.image.upload', 'brand.images'],
        canRemove: false,
      });
      instance.ui.addAssetLibraryEntry({ id: 'card.designs', sourceIds: premadeDesigns.allCategories });
      instance.ui.setReplaceAssetLibraryEntries((context) => {
        const { selectedBlocks, defaultEntryIds } = context;
        if (selectedBlocks.length !== 1) return [];
        const { id } = selectedBlocks[0];
        if (
          instance.engine.block.getName(id) !== BlockNames.RearImage &&
          instance.engine.block.getName(id) !== BlockNames.OutsideFrontImage
        ) {
          return defaultEntryIds;
        }
        return [...defaultEntryIds, 'card.designs'];
      });

      instance.ui.setDockOrder(basicOrderConfig);
      instance.ui.setInspectorBarOrder(basicInspectorBar);
      instance.ui.setCanvasMenuOrder(textModeCanvasMenuOrder, { editMode: 'Text' });

      instance.engine.variable.setString('lettrLabs', 'true');
      instance.engine.variable.setString('returnAddress', 'false');

      // * Add assets

      pastImages?.forEach((image) => {
        const img = new Image();
        img.src = image.blobUri;
        img.onload = () => {
          instance.engine.asset?.addAssetToSource('ly.img.image.upload', {
            id: image.id.toString(),
            meta: { thumbUri: image.blobUri, uri: image.blobUri, width: img.width, height: img.height },
          });
        };
      });

      instance.engine.asset?.addLocalSource('brand.images');
      brandImages?.forEach((image) => {
        const img = new Image();
        img.src = image.blobUri;
        img.onload = () => {
          instance.engine.asset?.addAssetToSource('brand.images', {
            id: image.id.toString(),
            meta: { thumbUri: image.blobUri, uri: image.blobUri, width: img.width, height: img.height },
          });
        };
      });

      instance.engine.asset?.addLocalSource('printed-typefaces');
      printedFonts.forEach((font) => {
        instance.engine.asset?.addAssetToSource('printed-typefaces', {
          id: font.id.toString(),
          label: { en: font.fontFamily },
          payload: {
            typeface: {
              name: font.fontFamily,
              fonts: [{ uri: font.fontPath, subFamily: 'Regular', weight: 'normal', style: 'normal' }],
            },
          },
        });
      });

      // * Register components

      instance.ui.registerComponent('editor.mode', ({ builder: { Button }, engine }) => {
        const currentOrder = instance.ui.getDockOrder();
        const hasElements = currentOrder.find((el) => typeof el !== 'string' && el.key === 'ly.img.elements');
        Button('modeButton', {
          variant: 'regular',
          label: `Change to ${hasElements ? 'Basic' : 'Advanced'}`,
          onClick: () => {
            instance.ui.setDockOrder(hasElements ? basicOrderConfig : advancedDockOrder);
            instance.ui.setInspectorBarOrder(hasElements ? basicInspectorBar : advancedInspectorBar);
          },
        });
      });

      instance.ui.registerComponent('img.fullBleed', ({ builder: { Button }, engine }) => {
        const selectedIds = engine.block.findAllSelected();
        if (
          selectedIds.length === 1 &&
          engine.block.getKind(selectedIds[0]).includes('image') &&
          engine.block.getName(selectedIds[0]) !== BlockNames.QRCode
        ) {
          Button('fullBleedButton', {
            label: 'Full Bleed',
            onClick: () => stretchImageToFullBleed(engine, selectedIds[0], template.product),
          });
        }
      });

      instance.ui.registerComponent('lettrLabs.dock', ({ builder: { Button } }) => {
        const isSelected = instance.engine.variable.getString('lettrLabs') === 'true';
        Button('lettrLabs.button', {
          label: 'Format',
          isSelected,
          onClick: () => {
            if (instance.ui.isPanelOpen('//ly.img.panel/assetLibrary')) {
              instance.ui.closePanel('//ly.img.panel/assetLibrary');
              instance.engine.variable.setString('lettrLabs', 'true');
            } else instance.engine.variable.setString('lettrLabs', isSelected ? 'false' : 'true');
            instance.engine.variable.setString('returnAddress', 'false');
          },
        });
      });

      instance.ui.registerComponent('returnAddress.dock', ({ builder: { Button } }) => {
        if (checkHasEnvelope(template.product)) return;
        const isSelected = instance.engine.variable.getString('returnAddress') === 'true';
        Button('returnAddress.button', {
          label: 'Return Address',
          isSelected,
          onClick: () => {
            if (instance.ui.isPanelOpen('//ly.img.panel/assetLibrary')) {
              instance.ui.closePanel('//ly.img.panel/assetLibrary');
              instance.engine.variable.setString('returnAddress', 'true');
            } else instance.engine.variable.setString('returnAddress', isSelected ? 'false' : 'true');
            instance.engine.variable.setString('lettrLabs', 'false');
          },
        });
      });

      // * Finish editor configuration

      setEngine(instance.engine);
    });

    return () => {
      cleanedUp = true;
      instance?.dispose();
      setEngine(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cesdkRef, fonts, isBrandImagesFetched, isPastImagesFetched, isPremadeDesignsFetched]);

  useEffect(() => {
    if (!engine?.event) return;
    const unsubscribe = engine.event.subscribe([], () => {
      setLettrLabsPanel(engine.variable.getString('lettrLabs') === 'true');
      setReturnAddressPanel(engine.variable.getString('returnAddress') === 'true');
    });
    return () => {
      unsubscribe();
    };
  }, [engine, setLettrLabsPanel, setReturnAddressPanel]);

  useEffect(() => {
    if (!engine?.block || !premadeDesigns) return;
    try {
      premadeDesigns?.allCategories?.forEach((c) => {
        engine.asset?.addLocalSource(c, undefined, async (asset) => {
          const rearImages = [
            ...engine.block.findByName(BlockNames.RearImage),
            ...engine.block.findByName(BlockNames.OutsideFrontImage),
          ];
          if (rearImages.length) {
            const fill = engine.block.getFill(rearImages[0]);
            engine.block.setString(fill, 'fill/image/imageFileURI', asset.meta.uri);
            setShowCardRear(true);
            return rearImages[0];
          }
        });
      });
      premadeDesigns?.designCategories?.forEach((designCategory) => {
        designCategory.files.forEach((file) => {
          engine.asset?.addAssetToSource(designCategory.name, {
            id: file.id.toString(),
            meta: { thumbUri: file.thumbUrl, uri: file.fullSizeUrl },
          });
        });
      });
    } catch (err) {
      console.error('Error fetching premade designs:', err);
    }
  }, [engine, premadeDesigns, setShowCardRear]);
}
